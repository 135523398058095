import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import CourtsView from './pages/CourtsView';

function App() {
  return (
    <div className='App'>
      <div className='page-container'>
        <div className='content-wrapper'>
          <Routes>
            <Route path='/' element={<CourtsView />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;

export const courtNames = [
  {
    courtID: 1,
    courtName: 'SEB 01',
    place: 2,
  },
  {
    courtID: 7,
    courtName: 'SEB 02',
    place: 2,
  },
  {
    courtID: 8,
    courtName: 'SEB 03',
    place: 2,
  },
  {
    courtID: 10,
    courtName: 'SEB 04',
    place: 2,
  },
  {
    courtID: 11,
    courtName: 'SEB 05',
    place: 2,
  },
  {
    courtID: 12,
    courtName: 'SEB 06',
    place: 2,
  },
  {
    courtID: 13,
    courtName: 'SEB 07',
    place: 2,
  },
  {
    courtID: 14,
    courtName: 'SEB 08',
    place: 2,
  },
  {
    courtID: 15,
    courtName: 'SEB 09',
    place: 2,
  },
  {
    courtID: 16,
    courtName: 'SEB 10',
    place: 2,
  },
  {
    courtID: 17,
    courtName: 'SEB 11',
    place: 2,
  },
  {
    courtID: 18,
    courtName: 'SEB 12',
    place: 2,
  },
  {
    courtID: 19,
    courtName: 'SEB 13',
    place: 2,
  },
  {
    courtID: 20,
    courtName: 'SEB 14',
    place: 2,
  },
  {
    courtID: 21,
    courtName: 'SEB 15',
    place: 2,
  },
  {
    courtID: 135,
    courtName: 'SEB 16',
    place: 18,
  },
  {
    courtID: 137,
    courtName: 'SEB 17',
    place: 18,
  },
  {
    courtID: 139,
    courtName: 'SEB 18',
    place: 18,
  },
  {
    courtID: 141,
    courtName: 'SEB 19',
    place: 18,
  },
  {
    courtID: 143,
    courtName: 'SEB 20',
    place: 18,
  },
  {
    courtID: 145,
    courtName: 'SEB 21',
    place: 18,
  },
  {
    courtID: 147,
    courtName: 'Centrinis (CC)',
    place: 18,
  },
  {
    courtID: 59,
    courtName: 'K1',
    place: 8,
  },
  {
    courtID: 61,
    courtName: 'K2',
    place: 8,
  },
  {
    courtID: 63,
    courtName: 'K3',
    place: 8,
  },
  {
    courtID: 65,
    courtName: 'K4',
    place: 8,
  },
  {
    courtID: 67,
    courtName: 'K5',
    place: 8,
  },
  {
    courtID: 69,
    courtName: 'K6',
    place: 8,
  },
];

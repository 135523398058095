export const times = [
  {
    value: '07:00:00',
    label: '07:00',
  },
  {
    value: '07:30:00',
    label: '07:30',
  },
  {
    value: '08:00:00',
    label: '08:00',
  },
  {
    value: '08:30:00',
    label: '08:30',
  },
  {
    value: '09:00:00',
    label: '09:00',
  },
  {
    value: '09:30:00',
    label: '09:30',
  },
  {
    value: '10:00:00',
    label: '10:00',
  },
  {
    value: '10:30:00',
    label: '10:30',
  },
  {
    value: '11:00:00',
    label: '11:00',
  },
  {
    value: '11:30:00',
    label: '11:30',
  },
  {
    value: '12:00:00',
    label: '12:00',
  },
  {
    value: '12:30:00',
    label: '12:30',
  },
  {
    value: '13:00:00',
    label: '13:00',
  },
  {
    value: '13:30:00',
    label: '13:30',
  },
  {
    value: '14:00:00',
    label: '14:00',
  },
  {
    value: '14:30:00',
    label: '14:30',
  },
  {
    value: '15:00:00',
    label: '15:00',
  },
  {
    value: '15:30:00',
    label: '15:30',
  },
  {
    value: '16:00:00',
    label: '16:00',
  },
  {
    value: '16:30:00',
    label: '16:30',
  },
  {
    value: '17:00:00',
    label: '17:00',
  },
  {
    value: '17:30:00',
    label: '17:30',
  },
  {
    value: '18:00:00',
    label: '18:00',
  },
  {
    value: '18:30:00',
    label: '18:30',
  },
  {
    value: '19:00:00',
    label: '19:00',
  },
  {
    value: '19:30:00',
    label: '19:30',
  },
  {
    value: '20:00:00',
    label: '20:00',
  },
  {
    value: '20:30:00',
    label: '20:30',
  },
  {
    value: '21:00:00',
    label: '21:00',
  },
  {
    value: '21:30:00',
    label: '21:30',
  },
  {
    value: '22:00:00',
    label: '22:00',
  },
  {
    value: '22:30:00',
    label: '22:30',
  },
];

export const duration = [
  {
    value: 30,
    label: '30 min',
  },
  {
    value: 60,
    label: '60 min',
  },
  {
    value: 90,
    label: '90 min',
  },
  {
    value: 120,
    label: '120 min',
  },
];

import { Box, Button, CardContent, Modal, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Select from 'react-select';
import CourtsTable from '../components/Table';
import { times, duration } from '../variables/times';
import { courtNames } from '../variables/courtNames';

const isMd = window.innerWidth >= 768;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: isMd ? 'auto' : '85%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};

const CourtsView = () => {
  const [courtsData, setCourtsData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [selectedStartTime, setSelectedStartTime] = useState('07:00:00');
  const [selectedEndTime, setSelectedEndTime] = useState('22:30:00');
  const [selectedDuration, setSelectedDuration] = useState(90);
  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const findCourtsByDate = (date) => {
    return courtsData.find((courts) => courts[0].date === date) || [];
  };

  const findFreeCourtsByDateTime = () => {
    const courtsOnSelectedDate = findCourtsByDate(selectedDate);
    const freeCourts = [];
    courtsOnSelectedDate.forEach((court) => {
      let startTime = moment(selectedStartTime, 'HH:mm:ss');
      const endTime = moment(selectedEndTime, 'HH:mm:ss');
      while (startTime.isBefore(endTime)) {
        let currentTime = startTime.clone();
        const durationEndTime = startTime.clone().add(selectedDuration, 'minutes');
        let isFreeDuringDuration = true;
        while (currentTime.isBefore(durationEndTime)) {
          const timeSlot = court.timetable[currentTime.format('HH:mm:ss')];
          if (!timeSlot || timeSlot.status !== 'free') {
            isFreeDuringDuration = false;
            break;
          }
          currentTime.add(30, 'minutes');
        }
        if (isFreeDuringDuration) {
          const courtName = courtNames.find((c) => c.courtID === court.courtID).courtName;
          if (!freeCourts.find((c) => c.courtName === courtName)) {
            freeCourts.push({
              courtName,
              availableStartTime: startTime.format('HH:mm:ss'),
              availableEndTime: durationEndTime.format('HH:mm:ss'),
            });
          }
        }
        startTime.add(30, 'minutes');
      }
    });
    return freeCourts;
  };

  const getNextTwoWeeksDates = () => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      dates.push(moment().add(i, 'days').format('YYYY-MM-DD'));
    }
    return dates;
  };

  useEffect(() => {
    const fetchData = async () => {
      const body = {
        excludeCourtName: true,
        excludeInfoUrl: true,
        places: [2, 18, 8],
        dates: [selectedDate],
        salePoint: 11,
        sessionToken: '',
      };

      const response = await fetch('https://ws.tenisopasaulis.lt/api/v1/placeInfoBatch', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (!data.data) {
        return;
      }
      const mergedData = data.data.reduce((accumulator, current) => {
        return accumulator.concat(current.data);
      }, []);

      const groupedByDate = {};

      mergedData.forEach((arr) => {
        const date = arr[0].date;
        if (!groupedByDate[date]) {
          groupedByDate[date] = [];
        }
        groupedByDate[date] = groupedByDate[date].concat(arr);
      });

      setCourtsData(Object.values(groupedByDate));
      setLoaded(true);
    };
    fetchData();
  }, [selectedDate]);

  if (!loaded) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Typography variant='h4' align='center' mt={2} mb={2}>
        SEB Arenos teniso kortai
      </Typography>
      <Box
        display='flex'
        justifyContent='space-evenly'
        width={{ xs: '100%', sm: '50%' }}
        mx='auto'
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems='center'
      >
        <DatePicker
          slotProps={{
            textField: {
              size: 'small',
              inputProps: {
                style: {
                  fontSize: '16px',
                  display: 'flex',
                },
              },
              InputLabelProps: {
                style: {
                  fontSize: '16px',
                },
              },
            },
          }}
          format='YYYY-MM-DD'
          value={moment(selectedDate)}
          onChange={(date) => setSelectedDate(date.format('YYYY-MM-DD'))}
          minDate={moment().endOf('day')}
        />
        <Select
          options={times}
          value={times.find((time) => time.value === selectedStartTime)}
          onChange={(time) => setSelectedStartTime(time.value)}
          styles={{
            control: (styles) => ({
              ...styles,
              backgroundColor: 'white',
              borderRadius: '4px',
              height: '40px',
              fontSize: '16px',
              color: '#4B5563',
              boxShadow: 'none',
              width: isMd ? '120px' : '246px',
              marginTop: isMd ? '0' : '8px',
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              return {
                ...styles,
                backgroundColor: isDisabled ? null : isSelected ? '#f5f5f5' : isFocused ? '#f5f5f5' : null,
                color: isDisabled ? '#ccc' : '#4B5563',
                cursor: isDisabled ? 'not-allowed' : 'default',
                fontSize: '16px',
                '&:active': {
                  backgroundColor: '#f5f5f5',
                },
              };
            },
            menu: (styles) => ({
              ...styles,
              marginTop: '4px',
              borderRadius: '4px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }),
            menuList: (styles) => ({
              ...styles,
              padding: '0',
              borderRadius: '4px',
            }),
            singleValue: (styles) => ({
              ...styles,
              color: '#4B5563',
              fontSize: '16px',
            }),
            placeholder: (styles) => ({
              ...styles,
              color: '#A0AEC0',
              fontSize: '16px',
            }),
          }}
        />
        <Select
          options={times}
          value={times.find((time) => time.value === selectedEndTime)}
          onChange={(time) => setSelectedEndTime(time.value)}
          styles={{
            control: (styles) => ({
              ...styles,
              backgroundColor: 'white',
              borderRadius: '4px',
              height: '40px',
              fontSize: '16px',
              color: '#4B5563',
              boxShadow: 'none',
              width: isMd ? '120px' : '246px',
              marginTop: isMd ? '0' : '8px',
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              return {
                ...styles,
                backgroundColor: isDisabled ? null : isSelected ? '#f5f5f5' : isFocused ? '#f5f5f5' : null,
                color: isDisabled ? '#ccc' : '#4B5563',
                cursor: isDisabled ? 'not-allowed' : 'default',
                fontSize: '16px',
                '&:active': {
                  backgroundColor: '#f5f5f5',
                },
              };
            },
            menu: (styles) => ({
              ...styles,
              marginTop: '4px',
              borderRadius: '4px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }),
            menuList: (styles) => ({
              ...styles,
              padding: '0',
              borderRadius: '4px',
            }),
            singleValue: (styles) => ({
              ...styles,
              color: '#4B5563',
              fontSize: '16px',
            }),
            placeholder: (styles) => ({
              ...styles,
              color: '#A0AEC0',
              fontSize: '16px',
            }),
          }}
        />
        <Select
          options={duration}
          value={duration.find((d) => d.value === selectedDuration)}
          onChange={(d) => setSelectedDuration(d.value)}
          styles={{
            control: (styles) => ({
              ...styles,
              backgroundColor: 'white',
              borderRadius: '4px',
              height: '40px',
              fontSize: '16px',
              color: '#4B5563',
              boxShadow: 'none',
              width: isMd ? '120px' : '246px',
              marginTop: isMd ? '0' : '8px',
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              return {
                ...styles,
                backgroundColor: isDisabled ? null : isSelected ? '#f5f5f5' : isFocused ? '#f5f5f5' : null,
                color: isDisabled ? '#ccc' : '#4B5563',
                cursor: isDisabled ? 'not-allowed' : 'default',
                fontSize: '16px',
                '&:active': {
                  backgroundColor: '#f5f5f5',
                },
              };
            },
            menu: (styles) => ({
              ...styles,
              marginTop: '4px',
              borderRadius: '4px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }),
            menuList: (styles) => ({
              ...styles,
              padding: '0',
              borderRadius: '4px',
            }),
            singleValue: (styles) => ({
              ...styles,
              color: '#4B5563',
              fontSize: '16px',
            }),
            placeholder: (styles) => ({
              ...styles,
              color: '#A0AEC0',
              fontSize: '16px',
            }),
          }}
        />
        <Button onClick={handleOpen} variant='contained' color='primary' sx={{ width: isMd ? 'auto' : '246px', marginTop: isMd ? '0' : '8px' }}>
          Gauti laisvus laikus
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: { xs: '90%', sm: 'auto' }, margin: 'auto' }}
        >
          <Box sx={style}>
            <CardContent sx={{ maxHeight: '70vh', overflow: 'auto' }}>
              {findFreeCourtsByDateTime().map((court) => (
                <Typography key={court.courtName} variant='body1' mb={1}>
                  <strong>{court.courtName}</strong>: nuo <strong>{court.availableStartTime}</strong> iki <strong>{court.availableEndTime}</strong>
                </Typography>
              ))}
            </CardContent>
          </Box>
        </Modal>
      </Box>
      <Box mt={2} p={2} style={{ backgroundColor: 'white' }}>
        <CourtsTable
          courtsData={findCourtsByDate(selectedDate)}
          selectedStartTime={selectedStartTime}
          selectedEndTime={selectedEndTime}
          selectedDuration={selectedDuration}
        />
      </Box>
    </Box>
  );
};

export default CourtsView;

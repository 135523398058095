import React from 'react';
import { useTable } from 'react-table';
import { courtNames } from '../variables/courtNames';

const CourtsTable = ({ courtsData, selectedStartTime, selectedEndTime, selectedDuration }) => {
  // Create a mapping of courtID to courtName
  const courtNameMap = courtNames.reduce((map, court) => {
    map[court.courtID] = court.courtName;
    return map;
  }, {});

  // Create an array of columns for the table
  const columns = React.useMemo(() => {
    const cols = [
      {
        Header: 'Court ID',
        accessor: 'courtID',
        Cell: ({ value }) => courtNameMap[value] || value, // Display the court name if it exists, otherwise display the courtID
      },
    ];

    // Get the hour from the selected start time and end time
    const startHour = parseInt(selectedStartTime.slice(0, 2), 10);
    const endHour = parseInt(selectedEndTime.slice(0, 2), 10);

    // Add a column for each full hour from the selected start time to the selected end time
    for (let i = startHour; i <= endHour; i++) {
      const time = `${i.toString().padStart(2, '0')}:00:00`;
      const nextTime = `${i.toString().padStart(2, '0')}:30:00`;
      cols.push({
        Header: time.slice(0, 5),
        accessor: (row) => ({ firstHalf: row.timetable[time]?.status, secondHalf: row.timetable[nextTime]?.status }),
        Cell: ({ value }) => {
          // Split the cell into two halves for each half-hour interval
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: '45%', height: '20px', backgroundColor: value.firstHalf === 'full' ? 'darkblue' : 'lightblue' }}></div>
              <div style={{ width: '45%', height: '20px', backgroundColor: value.secondHalf === 'full' ? 'darkblue' : 'lightblue' }}></div>
            </div>
          );
        },
      });
    }

    return cols;
  }, [selectedStartTime, selectedEndTime]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: courtsData });

  return (
    <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
      <table {...getTableProps()} style={{ width: '100%', minWidth: '600px' }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    maxWidth: '150px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    position: 'sticky',
                    top: 0,
                    background: '#fff',
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CourtsTable;
